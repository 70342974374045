import type { ActionTree, GetterTree, MutationTree } from 'vuex'
import { BaseInfoOutput } from '@alao-frontend/erp-services'
import { $api } from '~/plugins/axios'
import { RootState } from '~/store'

export const namespace = 'modules/BaseInfo'

export type BaseInfoState = BaseInfoOutput

export enum ActionType {
  FETCH_BASE_INFO = 'fetchBaseInfo',
}

export enum MutationType {
  SET_BASE_INFO = 'setBaseInfo',
}

export enum GetterType {
  PROVIDERS = 'providers',
  PROVIDER_BY_SLUG = 'providerBySlug',
  MOBILE_PROVIDERS = 'mobileProviders',
  HOME_PROVIDERS = 'homeProviders',
  MOBILE_PROVIDERS_WITH_TALK_TALK = 'mobileProvidersWithTalkTalk',
  AB_TESTS = 'abTests',
}

export const state = (): BaseInfoState => ({
  providers: [],
  ab_test: undefined,
})

export const getters: GetterTree<BaseInfoState, RootState> = {
  [GetterType.PROVIDERS]: state => state.providers,
  [GetterType.PROVIDER_BY_SLUG]: state => (slug: string) => state.providers.find(p => p.slug === slug),
  [GetterType.MOBILE_PROVIDERS]: state => state.providers.filter(p => p.mobile),
  [GetterType.HOME_PROVIDERS]: state => state.providers.filter(p => p.home).filter((provider: { slug: string }) => provider.slug !== 'swisscom'),
  [GetterType.MOBILE_PROVIDERS_WITH_TALK_TALK]: (_, getters) => {
    const providers = getters[GetterType.MOBILE_PROVIDERS].map((e: BaseInfoState['providers'][number]) => {
      return {
        name: e.title,
        extra: {
          logo: e.logo?.original,
        },
        value: e.slug,
        slug: e.slug,
      }
    }).filter((provider: { slug: string }) => provider.slug !== 'swisscom') // TODO: Should be resolved on backend
    // if talk-talk position in list more 5 than swap its position with elem which position in list is 5
    if (providers && providers[4].name !== 'TalkTalk') {
      const talkTalkProviderIndex = providers.findIndex((provider: BaseInfoState['providers'][number]) => provider.title === 'TalkTalk')
      if (talkTalkProviderIndex) {
        providers[4] = providers.splice(talkTalkProviderIndex, 1, providers[4])[0]
      }
    }
    return providers
  },
  [GetterType.AB_TESTS]: ({ ab_test }) => ab_test ? [ab_test] : [],
}

export const actions: ActionTree<BaseInfoState, RootState> = {
  async [ActionType.FETCH_BASE_INFO] ({ commit }) {
    const baseInfo = await $api.PublicBaseInfoService.getBaseInfo()
    commit(MutationType.SET_BASE_INFO, baseInfo)
  },
}

export const mutations: MutationTree<BaseInfoState> = {
  [MutationType.SET_BASE_INFO] (currentState, payload: BaseInfoState): void {
    currentState.providers = payload.providers
    currentState.ab_test = payload.ab_test
  },
}
