import type { AxiosError } from 'axios'

/**
 * APIException - an axios error wrapper that aim to provide clear error message to developers
 *
 * @see https://github.com/bottenderjs/messaging-apis/tree/master/packages/axios-error
 */

export class APIException extends Error {
  public readonly config: AxiosError['config']
  public readonly request?: AxiosError['request']
  public readonly response?: AxiosError['response']
  public readonly status?: number

  /**
   * The field is needed to be able to see
   * the data object at some loggers such as Sentry
   *
   * For some reasons, Sentry doesn't transform objects into JSON-string,
   * so we should do it manually to be able to see what data was returned from API
   * at the point an error occurred
   * */
  public readonly dataJSON?: string
  public readonly data?: string
  /**
   * @example
   * ```ts
   * new AxiosException('error message', { config, request, response })
   * ```
   */
  constructor (
    message: string,
    error: Pick<AxiosError, 'config' | 'request' | 'response'>
  );

  constructor (
    messageOrError: string | AxiosError,
    error?:
      | AxiosError
      | Pick<AxiosError, 'config' | 'request' | 'response'>,
  ) {
    let err: Pick<AxiosError, 'config' | 'request' | 'response'>

    if (typeof messageOrError === 'string') {
      super(messageOrError)
      err = error as Pick<AxiosError, 'config' | 'request' | 'response'>
    } else {
      super(messageOrError.message)
      err = messageOrError
    }

    /**
     * Set an error instance name explicitly instead of using `this.constructor.name`
     * as on project build `this.constructor.name` will be obfuscated and
     * other loggers such as Sentry logger won't be displaying exact error instance name
     */
    this.name = 'APIException'

    const { config, request, response } = err

    this.config = config
    this.request = request
    this.response = response
    this.status = response?.status || undefined
    this.data = this.response?.data
      ? this.response.data
      : ''
    this.dataJSON = this.response?.data
      ? this.stringifyObject(this.response.data)
      : ''
  }

  private stringifyObject (data: Record<string, any> | any[]) {
    try {
      return JSON.stringify(data, null, 2)
    } catch {
      return 'Unable to stringify response.data object'
    }
  }
}
